<template>
  <div class="pgoffice-page">
    <div class="tool" v-if="params?.ableGetValue">
      <button @click="getValue" class="btn">{{ "自动识别数据" }}</button>
      <div class="text">
        {{ text }}
      </div>
    </div>

    <input name="orderId" class="pg-input" id="orderId" type="text" :style="{ display: 'none' }" style="width: 180px"
      v-model="params.orderId" />
    <div id="pageOfficeContent" v-html="html_code" class="office-main"></div>
  </div>
</template>

<script>
import { SimpleFinalExcel_Excel } from "@/api/pageOffice";
import { getOrderValue } from "@/api/order"
export default {
  name: "order_excel_",
  data: function () {
    return {
      html_code: "",
      params: "",
      text: "当前数据未获取",
    };
  },
  methods: {
    Save: function () {
      document.getElementById("PageOfficeCtrl1").WebSave();
      let result = document.getElementById("PageOfficeCtrl1").CustomSaveResult;
      result = result.replaceAll("\\", "%2F")
      window.external.CallParentFunc(
        "PageOfficeCbFn('" + encodeURI(result) + "')"
      );
      window.external.close();
    },
    Close: function () {
      window.external.close();
    },
    getParam: function () {
      this.params = window.external.UserParams;
      if (this.params) {
        this.params = JSON.parse(decodeURI(this.params));
      }
    },
    init: function () {
      this.getParam();
      if (!this.params || this.params == "" || this.params == undefined) {
        alert("未获取到参数,打开失败");
        return;
      }
      SimpleFinalExcel_Excel(this.params).then((res) => {
        this.html_code = res;
      });
    },
    getValue: function () {
      this.text = "数据获取中";
      getOrderValue(this.params).then(res => {
        this.text = res;
      }).catch(e => {
        this.text = "数据获取失败"
      })
    }
  },
  created: function () {
    window.BeforeDocumentSaved = function (e) {
      console.log(e);
    };
    window.Save = this.Save;
    window.Close = this.Close;
    window.onCellClick = this.onCellClick;
    this.init();
  },
};
</script>

<style scoped lang="less">
.pgoffice-page {
  position: relative;
}

.tool {
  display: flex;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  row-gap: 20px;
}

.text {
  padding: 0 20px;
  box-sizing: border-box;
  font-size: 28px;
}

.btn {
  background-color: #366ab3;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  width: 240px;
  word-break: keep-all;
  border: none;
  border-radius: 4px;
}
</style>